<template>
  <div class="container">
    <div class="container-top">
      <a-radio-group default-value="CHINESE" v-model="subject" @change="changeSubject">
        <a-radio-button :value="item.value" v-for="item in subjectArr" :key="item.value">{{
          item.label
        }}</a-radio-button>
      </a-radio-group>
    </div>
    <div class="container-wrap">
      <div class="container-left">
        <div class="org-title">{{ getDdlLabel(subjectArr, subject) }}剩余课时/额度</div>
        <div class="example" v-if="iconLoading">
          <a-spin />
        </div>
        <div class="empty" v-if="courseUnitArr.length === 0 && !iconLoading">
          <img src="@/assets/icons/pic_emptypage.svg" alt="" />
          <p>暂无数据</p>
        </div>
        <div class="tree-wrap" v-else>
          <div class="item-wrap" v-for="(item, index) in courseUnitArr" :key="index">
            <div class="tree-title">{{ item.cnType }}：{{ item.remaining }}</div>
            <div class="tree-item" v-for="(subItem, index1) in item.expireRemaining" :key="index1">
              {{ subItem.expire === null ? '永久有效：' : `${subItem.expire}到期：` }}{{ subItem.remaining }}
            </div>
          </div>
        </div>
      </div>
      <div class="container-right">
        <div style="font-size: 16px; color: #333; font-weight: 600; margin-bottom: 15px">
          {{ getDdlLabel(subjectArr, subject) }}有效期详情
        </div>
        <div style="text-align: right; margin-bottom: 12px" v-if="showDelayBtn">
          <a-button type="primary" ghost @click="applyDelayFun" :loading="applyDelayLoading">申请延期</a-button>
        </div>
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="tableData"
          :pagination="initPagination"
          @change="handleTableChange"
          :scroll="{ y: 600 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template slot="expireTime" slot-scope="expireTime, record">
            <span>{{ expireTime === null ? '永久有效' : expireTime }}</span>
          </template>
          <template slot="orderNumber" slot-scope="orderNumber, record">
            <span
              style="color: #04cb94; cursor: pointer"
              @click="hrefOrder(orderNumber)"
              v-if="record.source === '订单购买'"
              >{{ orderNumber }}</span
            >
            <span v-else>{{ orderNumber }}</span>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal
      title="申请延期"
      :visible="visible"
      width="600px"
      :okButtonProps="{
        props: {
          loading: delayLoading,
        },
      }"
      @ok="onSubmit"
      dialogClass="schedule-related"
      @cancel="onCancel(0)"
    >
      <a-form ref="ruleForm" :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="small-txt">注意：只可对该学员购买课包申请延期，学员转移课时不可延期</div>
        <a-form-item label="当前科目">
          <a-select disabled placeholder="请选择" v-decorator="['subject']">
            <a-select-option :value="item.value" v-for="item in subjectArr" :key="item.value">{{
              item.label
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="当前有效期">
          <a-date-picker
            placeholder="请选择"
            disabled
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            v-decorator="['currentExpireDate']"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="申请延期/天">
          <a-input-number
            style="width: 100%"
            :min="0"
            :precision="0"
            placeholder="请输入"
            v-decorator="['delayDay', { rules: [{ required: true, message: '请填写课时数量' }] }]"
          />
        </a-form-item>
        <a-form-item label="延长原因">
          <a-select
            :options="delayOptions"
            placeholder="请选择"
            v-decorator="['reason', { rules: [{ required: true, message: '请选择原因' }] }]"
          />
        </a-form-item>
        <a-form-item label="上传图片">
          <a-upload
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="['imageUrl', { rules: [{ required: true, message: '请上传图片' }] }]"
          >
            <div v-if="fileList.length < 9">
              <a-icon type="plus" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="说 明">
          <a-textarea
            :maxLength="500"
            placeholder="请输入"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            v-decorator="['remark', { rules: [{ required: true, message: '请填写说明' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import sessionStorage from 'store/storages/sessionStorage';
import storage from 'store';
import {
  queryCourseUnit,
  queryCourseUnitDetails,
  getUploadOssToken,
  applyClassHourDelay,
  lookClassHourDelay,
} from '@/api/headTeacher';
import { imgpostUrl } from '@/api/register';
import { getDdlLabel } from '@/utils/util';

const session = storage.createStore(sessionStorage);

export default {
  name: 'periodValidity',
  data() {
    return {
      getDdlLabel,
      delayOptions: [
        { label: '学员冻结课程', value: '学员冻结课程' },
        { label: '学员延期开课', value: '学员延期开课' },
        { label: '学员课时转移', value: '学员课时转移' },
        { label: '学员学习进度过慢', value: '学员学习进度过慢' },
        { label: '到期前发生赠课兑换', value: '到期前发生赠课兑换' }
      ],
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      showDelayBtn: false,
      userInfo: {},
      studentProfile: session.get('studentProfile'),
      currentExpireDate: null,
      form: this.$form.createForm(this),
      delayLoading: false,
      applyDelayLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      visible: false,
      fileList: [],
      iconLoading: false,
      loading: false,
      courseUnitArr: [],
      studentId: '',
      subject: 'CHINESE',
      rangeTime: [],
      email: '',
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '生成时间',
          dataIndex: 'whenCreated',
          key: 'whenCreated',
          width: 180,
        },
        {
          title: '课时来源',
          dataIndex: 'source',
          key: 'source',
          width: 150,
        },

        {
          title: '课时有效期',
          dataIndex: 'expireTime',
          key: 'expireTime',
          scopedSlots: { customRender: 'expireTime' },
          width: 180,
        },

        {
          title: '相关订单',
          dataIndex: 'orderNumber',
          key: 'orderNumber',
          scopedSlots: { customRender: 'orderNumber' },
          width: 180,
        },
        {
          title: '剩余课时',
          dataIndex: 'cusInfo',
          key: 'cusInfo',
        },
      ],
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      current: 0,
      defaultExpandAll: true,
      tableData: [],

      fullName: undefined,
      jobNumber: undefined, // 工号
      userRole: undefined,
      jobCharacterArr: [
        { label: '全职', value: 'FULL_TIME_JOB' },
        { label: '兼职', value: 'PART_TIME_JOB' },
      ],
      jobCharacter: undefined,
      startDate: undefined,
      endDate: undefined,
      accountStatus: undefined,
      accountStatusArr: [
        { label: '正常', value: 'NORMAL' },
        { label: '禁用', value: 'LOCKED' },
      ],
      userRoleArr: [
        { label: '班主任', value: 'CLASS_ADMIN' },
        { label: '小悟空', value: 'X_WUKONG' },
        { label: '班主任主管', value: 'HEAD_TEACHER_SUPER' },
        { label: '班主任组长', value: 'HEAD_TEACHER_LEADER' },
        { label: '教务', value: 'TEACHING_STAFF' },
        { label: '教务主管', value: 'TEACHING_STAFF_ADMIN' },
      ],
      pageNum: 1,
      pageSize: 20,
      deptId: '',
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.delayLoading = true;
          const params = {
            delayDay: values.delayDay,
            subject: values.subject,
            studentId: this.studentProfile.uuid,
            currentExpireDate: this.$moment(values.currentExpireDate).format('YYYY-MM-DD HH:mm'),
            remark: values.remark,
            reason: values.reason,
          };

          if (this.fileList.length > 0) {
            params.images = this.fileList.map((item) => {
              return item.url;
            });
          }
          applyClassHourDelay(params)
            .then((res) => {
              this.$message.success('提交成功');
              this.delayLoading = false;
              this.visible = false;
            })
            .catch(() => {
              this.delayLoading = false;
            });
        }
      });
    },

    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
        })
        .finally(() => {});
    },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    onCancel() {
      this.visible = false;
      this.delayLoading = false;
      this.form.resetFields();
      this.fileList = [];
    },
    applyDelayFun() {
      this.applyDelayLoading = true;
      // 查看是否可以申请，查看过期时间
      const params = {
        subject: this.subject,
        studentId: this.studentProfile.uuid,
      };
      lookClassHourDelay(params)
        .then((res) => {
          const data = res.data.content;
          this.currentExpireDate = data.expire;
          if (!data.canAddExtraExpire) {
            this.$message.warning('当前学员科目课时/额度不可申请延期');
            return false;
          }
          this.visible = true;
          setTimeout(() => {
            this.form.setFieldsValue({
              subject: this.subject,
              currentExpireDate: this.$moment(this.currentExpireDate).format('YYYY-MM-DD HH:mm'),
            });
          });
        })
        .finally(() => {
          this.applyDelayLoading = false;
        });
    },
    // 跳转订单
    hrefOrder(orderNumber) {
      const routeUrl = this.$router.resolve({
        path: '/orderDetail',
        query: {
          orderNumber,
          studentId: this.studentId,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 获取左侧课时总览
    queryCourseUnit() {
      this.courseUnitArr = [];
      this.iconLoading = true;
      const params = {
        studentId: this.studentId,
        subject: this.subject,
      };
      queryCourseUnit(params).then((res) => {
        this.iconLoading = false;
        this.courseUnitArr = res.data.content;
      });
    },
    changeSubject(e) {
      this.subject = e.target.value;
      this.queryCourseUnit();
      this.queryCourseUnitDetails();
    },
    hrefStudent(record) {
      this.$router.push({ name: 'myStudent', params: { uuid: record.uuid, name: record.fullName } });
    },

    handleTableChange(data) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.queryCourseUnitDetails();
    },
    queryCourseUnitDetails() {
      this.loading = true;
      const params = {
        studentId: this.studentId,
        subject: this.subject,
        pageNum: this.pageNum,
      };
      queryCourseUnitDetails(params).then((res) => {
        this.loading = false;
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
        this.initPagination.pageSize = res.data.pageSize;
      });
    },
  },
  created() {
    this.studentId = this.$route.query.studentId;
  },
  mounted() {
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    const roleFLag = this.userInfo.roleArr.find((role) => role === 'CLASS_ADMIN');
    // 只有班主任才有权限申请延期
    if (roleFLag === 'CLASS_ADMIN') {
      this.showDelayBtn = true;
    }
    this.queryCourseUnit();
    this.queryCourseUnitDetails();
  },
};
</script>

<style lang="less" scoped>
.small-txt {
  padding-left: 66px;
  margin-bottom: 12px;
  color: #999;
}
.container {
  background-color: #fff;

  height: 92vh;
}
.container-wrap {
  display: flex;
}
.container-top {
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;
}
.container-left {
  width: 300px;
  height: 100%;
  height: 85vh;
  border-right: 1px solid #ddd;
}
.tree-title {
  color: #333;
  font-weight: bold;
}
.container-middle {
  flex: 1;
}
.org-title {
  font-size: 16px;
  color: #333;
  padding: 15px;
  font-weight: 600;
}

.tree-item {
  padding: 5px 15px 0;
}
.tree-wrap {
  padding: 15px;
  width: 300px;
  height: 78vh;
  overflow-y: scroll;
}
.container-right {
  padding: 15px;
  flex: 1;
}
.operation {
  color: #43d186;
  &:hover {
    cursor: pointer;
  }
}
.item-wrap {
  margin-bottom: 14px;
}
.empty {
  font-size: 16px;
  color: #999;
  padding-top: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30%;
}
.example {
  text-align: center;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>
