import { render, staticRenderFns } from "./periodValidity.vue?vue&type=template&id=494f5486&scoped=true&"
import script from "./periodValidity.vue?vue&type=script&lang=js&"
export * from "./periodValidity.vue?vue&type=script&lang=js&"
import style0 from "./periodValidity.vue?vue&type=style&index=0&id=494f5486&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494f5486",
  null
  
)

export default component.exports